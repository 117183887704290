import { createBrowserRouter, RouterProvider } from "react-router-dom";

import NotificationHandler from "./wrappers/notification";
import BrowserInactivity from "./wrappers/browser_inactivity_wrapper";

import { useSetDeviceHeader } from "./hooks/use_set_device_header";

import routes from "@/routes";

import "./index.css";
import "./global.less";

const router = createBrowserRouter(routes);

const App = () => {
  useSetDeviceHeader();

  return (
    <NotificationHandler>
      <BrowserInactivity>
        <RouterProvider router={router} />
      </BrowserInactivity>
    </NotificationHandler>
  );
};

export default App;
